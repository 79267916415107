import "./index.scss";
import "common/document_bundle/index.scss";

import { useState, useMemo } from "react";
import { useIntl, FormattedMessage, defineMessages } from "react-intl";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import classnames from "classnames";

import { useRecipientColors } from "common/pdf/recipient_colors/context";
import { deselectTool } from "redux/actions/pdf_menu";
import { useActiveOrganization } from "common/account/active_organization";
import { useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES } from "constants/notifications";
import PdfMenu from "common/pdf_menu";
import createSenderMenuData from "common/pdf_menu/create_sender_menu_data";
import { usePlaceholderData } from "common/pdf/interaction";
import { DocumentBundleMenu } from "common/document_bundle/menu";
import { ANNOTATION_SOURCES } from "constants/annotations";
import Button from "common/core/button";
import {
  useAnnotationDelete,
  useDesignationDelete,
  useDesignationGroupDelete,
  useDesignationGroupReassign,
  useDesignationGroupUpdateRequirements,
  useDesignationReassign,
  useUpdateDesignationOptionality,
  useDesignationAddToGroup,
} from "common/pdf/interaction/prep";
import { getDocumentFromId } from "util/document_bundle";
import { useIAnav } from "util/feature_detection";
import { onlyRequiresEsign } from "util/completion_requirements/completion_requirements_text";
import WorkflowModal from "common/modals/workflow_modal";
import { usePermissions } from "common/core/current_user_role";
import Modal from "common/modal";
import { useFeatureFlag } from "common/feature_gating";
import { ROUTE as TEMPLATES_ROUTE } from "common/tools/document_templates";
import { MOVE_TOOLS, REPLACE_DOC } from "constants/feature_gates";

import { TemplatePrepPdf } from "./pspdfkit";
import OrganizationTemplatesPrepareQuery, {
  type OrganizationTemplatesPrepare_viewer_user as User,
  type OrganizationTemplatesPrepare_organization_Organization as Organization,
  type OrganizationTemplatesPrepare_template_OrganizationDocumentTemplate as Template,
  type OrganizationTemplatesPrepare_template_OrganizationDocumentTemplate_documentBundle_documents_edges_node as Document,
  type OrganizationTemplatesPrepare_template_OrganizationDocumentTemplate_documentBundle_documents_edges_node_designations_edges_node as Designation,
  type OrganizationTemplatesPrepare_template_OrganizationDocumentTemplate_documentBundle_documents_edges_node_designationGroups as DesignationGroup,
} from "./query.graphql";

type LoadedProps = {
  user: User;
  organization: Organization;
  template: Template;
};
function SignatureModal({ buttonAction }: { buttonAction: () => void }) {
  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="9efa0f7e-43e8-4f15-86a7-1a3d70f205f7"
          defaultMessage="Add a signature field for your signers"
        />
      }
      buttons={[
        <Button
          key="fill-out-document"
          onClick={buttonAction}
          buttonColor="action"
          variant="primary"
        >
          <FormattedMessage
            id="3324834f-1473-4726-9356-2852a5254397"
            defaultMessage="Fill out document"
          />
        </Button>,
      ]}
    >
      <FormattedMessage
        id="4fb8d663-298e-4b3d-ab8d-5b8d96ea3469"
        defaultMessage="You must add at least one signature field for each signer before saving."
      />
    </WorkflowModal>
  );
}

function LoadedOrganizationTemplatesPrepare({ user, organization, template }: LoadedProps) {
  const intl = useIntl();
  const recipientColors = useRecipientColors();
  const placeholderData = usePlaceholderData();
  const navigate = useNavigate();
  const isIaNav = useIAnav();
  const { search, pathname } = useLocation();
  const moveTools = useFeatureFlag(MOVE_TOOLS);
  // BIZ-7111: Always use the new hardcoded tools path.
  const basePath = moveTools ? `tools/${TEMPLATES_ROUTE}` : pathname.split("/")[1];
  const {
    preset,
    documentBundle,
    documentBundle: { documents },
  } = template;

  // BIZ-6367: Combine selectedAnnotationOrDesignationId, selectedDesignationGroup, and conditionalEditMode into a shared hook
  const [selectedAnnotationOrDesignationId, setSelectedAnnotationOrDesignationId] = useState<
    string | null
  >(null);
  const [selectedDocumentId, setSelectedDocumentId] = useState<string>(documents.edges[0].node.id);
  const isRealEstateOrg = organization.lenderAccess || organization.titleAgencyAccess;
  const selectedDocument = getDocumentFromId(documents, selectedDocumentId) as Document;
  const designations = selectedDocument.designations.edges.map((edge) => edge.node);
  const conditionalRules = selectedDocument.conditionalRules as Record<string, string[]>;

  const [conditionalEditMode, setConditionalEditMode] = useState(false);
  const [preExistingConditionalDesignations, setPreExistingConditionalDesignations] = useState(
    {} as Record<string, string[]>,
  );
  const dispatch = useDispatch();
  const { hasPermissionFor } = usePermissions();

  function toggleConditionalEditMode(val: boolean, shouldShowToast: boolean = true) {
    if (val === conditionalEditMode) {
      return;
    } else if (val) {
      setPreExistingConditionalDesignations(conditionalRules);
    } else {
      dispatch(deselectTool());
      if (shouldShowToast) {
        showToast();
      }
    }
    setConditionalEditMode(val);
  }

  function showToast() {
    const currentDependentsForSelected =
      selectedAnnotationOrDesignationId &&
      Boolean(conditionalRules[selectedAnnotationOrDesignationId])
        ? conditionalRules[selectedAnnotationOrDesignationId]
        : [];
    const previousDependentsForSelected =
      selectedAnnotationOrDesignationId &&
      Boolean(preExistingConditionalDesignations[selectedAnnotationOrDesignationId])
        ? preExistingConditionalDesignations[selectedAnnotationOrDesignationId]
        : [];
    const newConditionalRules =
      currentDependentsForSelected.length - previousDependentsForSelected.length;
    if (newConditionalRules > 0) {
      const MESSAGES = defineMessages({
        rulesAdded: {
          id: "a40923f9-d41e-4a78-b5a8-eea3ebed6894",
          defaultMessage:
            "{newConditionalRules} {newConditionalRules, plural, one {conditional field} other {conditional fields}} added",
        },
      });

      return pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        message: intl.formatMessage(MESSAGES.rulesAdded, {
          newConditionalRules,
        }),
      });
    }
  }

  function changeSelectedAnnotationOrDesignationId(annotationOrDesignationId: string | null) {
    // If in CEM and user clicks alternate designation, turn off CEM
    // If in CEM and user clicks on primary designation or no designation, remain in CEM
    if (
      conditionalEditMode &&
      annotationOrDesignationId &&
      annotationOrDesignationId !== selectedAnnotationOrDesignationId
    ) {
      toggleConditionalEditMode(false);
      // If not in CEM, just set selected to whatever it is
    } else if (!conditionalEditMode) {
      setSelectedAnnotationOrDesignationId(annotationOrDesignationId);
    }
  }

  const documentRequirements = {
    notaryRequired:
      selectedDocument.notarizationRequired ||
      selectedDocument.proofingRequired ||
      selectedDocument.signingRequiresMeeting,
    witnessRequired: selectedDocument.witnessRequired,
  };

  const hasTemplatePresetPermission = hasPermissionFor("templatePreset");

  const selectedAnnotationOrDesignation = (
    selectedDocument.designations.edges.find(
      (edge) => edge.node.id === selectedAnnotationOrDesignationId,
    ) ||
    selectedDocument.annotations.edges.find(
      (edge) => edge.node.id === selectedAnnotationOrDesignationId,
    )
  )?.node;
  const selectedDesignationGroup = selectedAnnotationOrDesignation
    ? selectedDocument.designationGroups.find(
        (group) =>
          selectedAnnotationOrDesignation.__typename === "AnnotationDesignation" &&
          group.id === selectedAnnotationOrDesignation.designationGroupId,
      )
    : null;

  const menuData = createSenderMenuData({
    signersData: placeholderData,
    hideSignerAnnotationTools: true,
    notaryRequired: documentRequirements.notaryRequired,
    witnessRequired: documentRequirements.witnessRequired,
    hidden: selectedDocument.hidden,
    hideMultisignerOnlyTools: !preset,
    // the only use case we have for free text designations in REAL is on meeting required full RON docs
    // so the notary/signer are reminded to fill something out. We don't allow on non-meeting docs because
    // we do not want the signer to put in w/e they want and potentially mess up the docs. We assume closing ops knows
    // so they are allowed to add text designations
    hideFreeTextDesignation: isRealEstateOrg && !hasTemplatePresetPermission,
    intl,
    primaryDesignationId: conditionalEditMode && selectedAnnotationOrDesignationId,
    recipientColors,
  });

  const handleAnnotationDelete = useAnnotationDelete(
    user.id,
    selectedDocument.id,
    selectedDocument.locked,
  );
  const handleDesignationDelete = useDesignationDelete(
    selectedDocument.id,
    selectedDocument.designations.totalCount,
  );
  function processDesignationDelete(designation: Designation) {
    handleDesignationDelete(designation);
    toggleConditionalEditMode(false, false);
  }

  const handleDesignationGroupDelete = useDesignationGroupDelete(selectedDocument.id, designations);
  function processDesignationGroupDelete(designationGroup: DesignationGroup) {
    handleDesignationGroupDelete(designationGroup);
    toggleConditionalEditMode(false, false);
  }

  const handleDesignationAddToGroup = useDesignationAddToGroup(
    selectedDocument.id,
    selectedDocument.designations.edges.map((edge) => edge.node),
  );
  function processDesignationAddToGroup(sourceDesignation: Designation) {
    handleDesignationAddToGroup(sourceDesignation);
    toggleConditionalEditMode(false);
  }

  const handleDesignationReassign = useDesignationReassign();
  const handleDesignationGroupReassign = useDesignationGroupReassign(designations);
  const handleDesignationGroupUpdateRequirements = useDesignationGroupUpdateRequirements();
  const handleSetOptional = useUpdateDesignationOptionality();
  const designationHandlers = useMemo(
    () => ({
      onDelete: processDesignationDelete,
      onDeleteGroup: processDesignationGroupDelete,
      onAddToGroup: processDesignationAddToGroup,
      onReassign: handleDesignationReassign,
      onReassignGroup: handleDesignationGroupReassign,
      onUpdateGroupRequirements: handleDesignationGroupUpdateRequirements,
      onSetOptional: handleSetOptional,
    }),
    [
      processDesignationDelete,
      processDesignationGroupDelete,
      processDesignationAddToGroup,
      handleDesignationReassign,
      handleDesignationGroupReassign,
      handleDesignationGroupUpdateRequirements,
      handleSetOptional,
    ],
  );
  const annotationHandlers = useMemo(
    () => ({ onDelete: handleAnnotationDelete }),
    [handleAnnotationDelete],
  );

  const conditionalEditModeDetails = {
    toggleConditionalEditMode,
    conditionalEditMode,
  };

  const eSignHasDesignation = () => {
    const canSignerAnnotate = documents.edges.some((doc) => {
      return doc.node.signerCanAnnotate;
    });

    const docDesignations = documents.edges.map(({ node }) => ({
      designations: node.designations,
    }));

    const hasSignatureDesignation = docDesignations.some((doc) =>
      doc.designations.edges.some((edge) => {
        return edge.node.type === "SIGNATURE";
      }),
    );
    return canSignerAnnotate || hasSignatureDesignation;
  };

  const [showSignatureDesignationModal, setShowSignatureDesignationModal] = useState(false);

  const replaceDocumentEnabled = useFeatureFlag(REPLACE_DOC);

  return (
    <Modal className={conditionalEditMode ? "PrepareModal--banner-present" : "PrepareModal"}>
      {showSignatureDesignationModal && (
        <SignatureModal buttonAction={() => setShowSignatureDesignationModal(false)} />
      )}
      <div
        className={classnames(
          "DocumentBundle DocumentBundle__with-menu",
          isIaNav && "DocumentBundle__fullscreen",
        )}
        data-automation-id="document-bundle"
      >
        <div className="DocumentBundle--viewer-container">
          <DocumentBundleMenu
            bundle={documentBundle}
            selectedDocument={selectedDocument}
            annotationSource={ANNOTATION_SOURCES.NONE}
            onDocumentSelected={setSelectedDocumentId}
            showVisibilityControl
          />
          <TemplatePrepPdf
            document={selectedDocument}
            currentUser={user}
            setSelectedAnnotationOrDesignationId={changeSelectedAnnotationOrDesignationId}
            selectedAnnotationOrDesignationId={selectedAnnotationOrDesignation?.id}
            selectedDesignationGroupId={selectedDesignationGroup?.id}
            conditionalEditModeDetails={conditionalEditModeDetails}
          />
        </div>

        <div className="DocumentBundle--menu-container">
          <PdfMenu
            data={menuData}
            title={
              <FormattedMessage
                id="2a64598f-3ff3-4755-a422-bc51d2de71fe"
                defaultMessage="Prepare Template"
              />
            }
            hasPermissionFor={hasPermissionFor}
            button={
              <Button
                buttonColor="action"
                variant="primary"
                automationId="continue-button"
                onClick={
                  onlyRequiresEsign(documentBundle.completionRequirements) && !eSignHasDesignation()
                    ? () => {
                        setShowSignatureDesignationModal(true);
                      }
                    : () => {
                        navigate(`/${basePath}${search}`);
                      }
                }
              >
                {replaceDocumentEnabled ? (
                  <FormattedMessage
                    id="b608b3d5-2eb7-42a3-8940-29eb80edadaa"
                    defaultMessage="Save & close"
                  />
                ) : (
                  <FormattedMessage
                    id="b15f743b-4919-4bec-923c-2b72993dd3d5"
                    defaultMessage="Save Template"
                  />
                )}
              </Button>
            }
            selectedAnnotationOrDesignation={selectedAnnotationOrDesignation}
            selectedDesignationGroup={
              selectedDesignationGroup && {
                ...selectedDesignationGroup,
                size: designations.filter(
                  (d) => d.designationGroupId === selectedDesignationGroup.id,
                ).length,
              }
            }
            document={selectedDocument}
            designationHandlers={designationHandlers}
            annotationHandlers={annotationHandlers}
            conditionalEditModeDetails={conditionalEditModeDetails}
            showAnchorTextTags
          />
        </div>
      </div>
    </Modal>
  );
}

export default function OrganizationTemplatesPrepareContainer() {
  const templateId = useParams().globalID!;
  const [activeOrganizationId] = useActiveOrganization();
  const { data, loading } = useQuery(OrganizationTemplatesPrepareQuery, {
    variables: {
      templateId,
      organizationId: activeOrganizationId!,
    },
  });

  if (loading || !data) {
    return <LoadingIndicator />;
  }

  return (
    <LoadedOrganizationTemplatesPrepare
      user={data.viewer.user!}
      organization={data.organization as Organization}
      template={data.template as Template}
    />
  );
}
